import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFnType } from 'types';
import { ApiErrorResponse, BulkPayoutResponse } from 'types/api';
import { BeneficiaryPayoutEligibilty, BeneficiaryType } from 'types/beneficiary';
import { Beneficiary } from 'types/beneficiary-v2';
import { InternalTransferRFQQuoteResponse } from 'types/internalTransferApi.types';

export interface TransactionMetadataType {
  label: string;
  value: string;
  type?: string;
  action?: any;
}
export interface TransactionType {
  id: string;
  transfer_type: TxnType;
  reference_id: string;
  status: TxnStatus;
  parent_id: string;
  quote_id: string;
  corridor_id: string;
  beneficiary_id: string;
  source_amount: number;
  receiving_amount: number;
  exchange_rate: number;
  total_cost: number;
  transaction_hash: string;
  beneficiary_name: string;
  source_currency_code: string;
  source_account_address: string;
  dest_currency_code: string;
  fixed_cost_currency: string;
  source_currency_display_name: string;
  dest_currency_display_name: string;
  beneficiary_account_number: string;
  beneficiary_bank_name: string;
  created_at: string;
  updated_at: string;
  beneficiary_routing_code_value_1: string;
  transfer_method: string;
  beneficiary_routing_code_type_1: string;
  transaction_link: string;
  bank_reference_id: string;
  meta_data?: TransactionMetadataType[];
}

export interface TransactionListItemType {
  [TransactionListColumns.DATE]: string;
  [TransactionListColumns.TO_FROM]: string;
  [TransactionListColumns.AMOUNT]: number;
  [TransactionListColumns.CURRENCY]: string;
  [TransactionListColumns.PAYMENT_METHOD]: string;
  [TransactionListColumns.STATUS]: TxnStatus;
}

export enum TransactionListColumns {
  DATE = 'DATE',
  TO_FROM = 'TO/FROM',
  AMOUNT = 'AMOUNT',
  CURRENCY = 'CURRENCY',
  PAYMENT_METHOD = 'PAYMENT METHOD',
  STATUS = 'STATUS',
}

export enum TxnType {
  CREDIT = 'credit',
  DEBIT = 'debit',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum PaymentMethod {
  CRYPTO = 'CRYPTO',
  BANK_TRANSFER = 'BANK_TRANSFER',
  FIAT = 'FIAT',
  TREASURY = 'Treasury',
  BULK_TRANSFER = 'BULK',
}

export enum TxnStatus {
  IN_PROCESS = 'initiated',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  VALIDATED = 'validated',
  CREATED = 'created',
  VALIDATION_FAILED = 'validation_failed',
  TNC_PENDING = 'tnc_pending',
  PARTIAL_SUCCESS = 'partial_succeeded',
  IN_REVIEW = 'in_review',
  FAILED_DUE_TO_COMPLIANCE = 'failed_due_to_compliance',
  DELAYED = 'delayed',
  RFI_REQUESTED = 'rfi_requested',
}

export enum TransactionDetailsHeadings {
  TRANSACTION_ID = 'TRANSACTION ID',
  DATE = 'DATE',
  INITIATOR = 'INITIATOR',
  SENDER = 'SENDER',
  BENEFICIARY = 'BENEFICIARY',
  SOURCE_AMOUNT = 'SOURCE AMOUNT',
  RECEIVING_AMOUNT = 'RECEIVING AMOUNT',
  REF_NOTE = 'REFERENCE NOTE',
  TRANSACTION_HASH = 'TRANSACTION HASH',
  BANK_REF_NO = 'BANK REFERENCE NUMBER',
  STATUS = 'STATUS',
  TOTAL_FEES = 'TOTAL FEES',
}

export interface FiatRFQRequestType {
  corridor_id: string;
  beneficiary_id: string;
  source_amount?: number;
  receiving_amount?: number;
}

export interface FiatRFQType {
  id: string;
  corridor_id: string;
  source_amount: number;
  exchange_rate: number;
  receiving_amount: number;
  fixed_cost: number;
  total_fees: number;
  total_fees_currency: string;
  converted_amount: number;
  created_at: string;
  updated_at: string;
  expired_at: string;
  gas_fees?: number;
  platform_fees?: number;
}

export interface FiatPayoutType {
  quote_id: string;
  reference_id?: string;
  comments?: string;
  should_sync?: boolean;
  chart_of_accounts_id?: string | number;
}

export interface CryptoPayoutType {
  quote_id: string;
  reference_id?: string;
}

export interface CryptoRFQType {
  id: string;
  corridor_id: string;
  source_amount: number;
  exchange_rate: number;
  receiving_amount: number;
  total_fees_currency: string;
  fixed_cost_currency: string;
  total_fees: number;
  total_cost: number;
  converted_amount: number;
  created_at: string;
  updated_at: string;
  expired_at: string;
}

export interface BulkPayout {
  full_name: string;
  address: string;
  currency_code: string;
  amount: string;
}

export interface BulkTransaction extends BulkPayoutResponse {
  file_url: string;
  total_amount: number;
  total_fees: number;
  transfer_method: string;
  no_of_payouts: number;
  source_currency_code: string;
  total_beneficiaries: number;
  account_id?: string;
  source_currency: string;
}

export type CompletedInfoParams = {
  amount?: number;
  currency?: string;
  type?: string;
};

export interface CurrencyConfigForBeneficiary {
  dest_currency_code: string;
  routing_type: string;
  routing_value: string;
  source_currencies: SourceCurrency[];
}

export interface SourceCurrency {
  label?: string;
  value?: string | number;
  id?: string;
  corridor_id: string;
  code: string;
  display_name: string;
  routing_type: string;
  routing_value: string;
  source_account_id?: string;
  source_currency_min_value: number;
  source_currency_max_value: number;
  dest_currency_min_value: number;
  dest_currency_max_value: number;
  corridor_type: CORRIDOR_TYPE;
  account_id?: string;
}

export interface RfqPayload {
  corridor_id: string;
  receiving_amount?: number;
  source_amount?: number;
  beneficiary_id: string;
  account_id?: string;
  slippage?: number;
}

export interface RfqData {
  id: string;
  corridor_id: string;
  source_amount: number;
  exchange_rate: number;
  receiving_amount: number;
  total_fees_currency: string;
  total_fees: number;
  converted_amount: number;
  created_at: string;
  updated_at: string;
  expired_at: string;
  is_quote_indicative: boolean;
  gas_fees?: number;
  platform_fees?: number;
}

export interface DestCurrency {
  label?: string;
  value?: string | number;
  id?: string;
  dest_account_id?: string;
  code: string;
  sourceCurrencies: SourceCurrency[];
}

export type CompletedActionParams = {
  sourceCurrency: SourceCurrency;
  destCurrency: DestCurrency;
};

export enum CORRIDOR_TYPE {
  CRYPTO_CRYPTO = 'CRYPTO_CRYPTO',
  FIAT_FIAT = 'FIAT_FIAT',
  CRYPTO_FIAT = 'CRYPTO_FIAT',
  FIAT_CRYPTO = 'FIAT_CRYPTO',
  CRYPTO_CRYPTO_BRIDGE = 'CRYPTO_CRYPTO_BRIDGE',
}

export interface TransferDetailsProps {
  amountDetails?: RfqData;
  recipient?: BeneficiaryType | null;
  amount?: string;
  className?: string;
  onBackPress: () => void;
  setCompleted: (params: CompletedActionParams) => void;
  setQuotePayload: (params: RfqPayload) => void;
  getQuote: (params: RfqPayload) => void;
  cancelQuoteRefetch: () => void;
  conversionLoading: boolean;
  conversionErr: ApiErrorResponse;
  selectedDestCurrency?: DestCurrency;
  selectedSourceCurrency?: SourceCurrency;
}

export interface ThirdPartyTransferDetailsParams {
  selectedSourceCurrency: SourceCurrency;
  selectedDestCurrency: DestCurrency;
  selectedAmountInfo?: {
    [AmountType.SOURCE_AMOUNT]: string;
    [AmountType.DEST_AMOUNT]: string;
    isSourceAmount: boolean;
  };
  recipientId?: string;
  needAdditionalDetails?: boolean;
  beneData?: BeneficiaryPayoutEligibilty;
}

export interface ThirdPartyTransferDetailsProps extends ThirdPartyTransferDetailsParams {
  selectedRecipientAccount?: BeneficiaryType | null;
  selectedSourceAccount?: any;
  rfqDetails?: RfqData;
  rfqLoading: boolean;
  rfqUninitialized?: boolean;
  rfqError: ApiErrorResponse;
  clearRfqTimer: defaultFnType;
  setRfqPayload: (params: RfqPayload) => void;
  createRfq: (params: RfqPayload) => void;
  handleSubmit?: (params: ThirdPartyTransferDetailsParams) => void;
  onError: defaultFnType;
}

export enum AmountType {
  SOURCE_AMOUNT = 'sourceAmount',
  DEST_AMOUNT = 'destAmount',
}

export type AmountInfoType = {
  sourceAmount: string;
  destAmount: string;
  isSourceAmount: boolean;
};

export interface ReviewAndPayProps {
  recipient: BeneficiaryType | null | undefined;
  quotePayload: RfqPayload;
  quoteId: string;
  onBackPress: () => void;
  onSuccess: () => void;
  onError: () => void;
  onPayoutClick?: (params: CompletedInfoParams) => void;
  isQuoteAutoUpdated: boolean;
  selectedSourceCurrency?: SourceCurrency;
  selectedDestCurrency?: DestCurrency;
  handleSubmit?: (data: any) => void;
}

export interface ThirdPartyReviewAndPayProps {
  selectedSourceAccount?: any;
  selectedSourceCurrency?: SourceCurrency;
  selectedDestCurrency?: DestCurrency;
  quotePayload: RfqPayload;
  quoteId: string;
  isQuoteAutoUpdated: boolean;
  handleBack: defaultFnType;
  onSuccess?: (params: CompletedInfoParams) => void;
  onError: (error?: ApiErrorResponse) => void;
  setFiatQuoteData?: (data: FiatRFQType) => void;
  recipientAccount?: Beneficiary;
}

export interface AmountDetailsProps {
  transferDetails: FiatRFQType | RfqData | InternalTransferRFQQuoteResponse | undefined;
  destinationCurrency: string;
  className?: string;
  corridor?: CORRIDOR_TYPE;
  sourceCurr?: string;
  itemsParentWrapperClassName?: string;
  itemsWrapperClassName?: string;
  currencyTextClassName?: string;
  labelClassName?: string;
  showIcon?: boolean;
  iconClassName?: string;
  iconStyle?: { width?: number; height?: number };
  feeBreakupItemClassName?: string;
  feeBreakupWrapperClassName?: string;
  feeBreakupValueClassName?: string;
}

export type FILTER_ICON_MAP_TYPE = { id: string; iconCategory: ICON_SPRITE_TYPES };
